var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('b-card',{staticStyle:{"max-width":"95rem"}},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e(),_c('b-alert',{attrs:{"variant":"danger","show":!_vm.loading && _vm.loadingError ? true : false}},[_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v(_vm._s(_vm.loadingError))])])]),(!_vm.loading && !_vm.loadingError)?_c('validation-observer',{ref:"profileForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-alert',{attrs:{"variant":"danger","show":_vm.errorMessage !== null ? true : false}},[_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v(_vm._s(_vm.errorMessage))])])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Template Name","label-for":"template-name","label-cols-md":"3","label-cols-lg":"2"}},[_c('b-form-input',{attrs:{"id":"template-name","value":_vm.templateName,"readonly":""}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name","vid":"name","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","label-for":"name","label-class":"font-1rem"}},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,843047394)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Project","vid":"project","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Project","label-for":"project","label-class":"font-1rem","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"project","options":_vm.options.projects},on:{"input":function($event){return _vm.onChangeProject(_vm.project)}},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2154323537)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Document Type","vid":"document-docType","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Document Type","label-for":"document-docType","label-class":"font-1rem","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"document-docType","options":_vm.documentTypes,"no-drop":!_vm.project,"disabled":!_vm.options.projects.length},on:{"search:focus":function($event){_vm.documentTypeFocused = true},"search:blur":function($event){_vm.documentTypeFocused = false}},model:{value:(_vm.documents.docType),callback:function ($$v) {_vm.$set(_vm.documents, "docType", $$v)},expression:"documents.docType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3040910889)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Country Code","vid":"countryCode","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country Code","label-for":"country-code","label-class":"font-1rem","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"country-code","options":_vm.countryOptions,"label":"label","reduce":function (option) { return option.value; }},model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,312540907)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"File Type","vid":"document-FileType","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"File Type","label-for":"document-FileType","label-class":"font-1rem","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"document-FileType","options":['.xlsx']},model:{value:(_vm.documents.FileType),callback:function ($$v) {_vm.$set(_vm.documents, "FileType", $$v)},expression:"documents.FileType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1795981348)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Language","vid":"document-language","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Language","label-for":"document-language","label-class":"font-1rem","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"document-language","options":_vm.options.language},model:{value:(_vm.documents.language),callback:function ($$v) {_vm.$set(_vm.documents, "language", $$v)},expression:"documents.language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1860651871)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"OCR Engine","vid":"document-OCREngine","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"OCR Engine","label-for":"document-OCREngine","label-class":"font-1rem","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"document-OCREngine","options":['S', 'P', 'A']},model:{value:(_vm.documents.OCREngine),callback:function ($$v) {_vm.$set(_vm.documents, "OCREngine", $$v)},expression:"documents.OCREngine"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,521041079)})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Page Rotage","vid":"document-pageRotage","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"align-checkbox",attrs:{"label":"Page Rotage","label-for":"document-pageRotage","label-class":"font-1rem"}},[_c('b-form-checkbox',{attrs:{"id":"document-pageRotage","switch":""},model:{value:(_vm.documents.pageRotage),callback:function ($$v) {_vm.$set(_vm.documents, "pageRotage", $$v)},expression:"documents.pageRotage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2643648291)})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Barcode","vid":"document-barcode","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"align-checkbox",attrs:{"label":"Barcode","label-for":"document-barcode","label-class":"font-1rem"}},[_c('b-form-checkbox',{attrs:{"id":"document-barcode","switch":""},model:{value:(_vm.documents.barcode),callback:function ($$v) {_vm.$set(_vm.documents, "barcode", $$v)},expression:"documents.barcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2351253859)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"ExDefinition","vid":"document-definition","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"align-checkbox",attrs:{"label":"Use Existing Definition","label-for":"document-definition","label-class":"font-1rem"}},[_c('b-form-checkbox',{attrs:{"id":"document-definition","switch":""},model:{value:(_vm.documents.useExistingDefinition),callback:function ($$v) {_vm.$set(_vm.documents, "useExistingDefinition", $$v)},expression:"documents.useExistingDefinition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,262196259)})],1),(_vm.documents.useExistingDefinition)?_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Existing Profiles","vid":"existing-profile-name","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Select Existing Profile","label-for":"existing-profile-name","label-class":"font-1rem","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"existing-profile-name","options":_vm.allDefinitions,"label":"label","disabled":!_vm.allDefinitions.length},on:{"input":function($event){return _vm.onChangeDefinitions(true)}},model:{value:(_vm.documents.existingProfileName),callback:function ($$v) {_vm.$set(_vm.documents, "existingProfileName", $$v)},expression:"documents.existingProfileName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,708445587)})],1):_vm._e(),(_vm.documents.useExistingDefinition)?_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Existing Document Type","vid":"existing-document-type","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Select Existing Document","label-for":"existing-document-type","label-class":"font-1rem","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"existing-document-type","options":_vm.typesByDefinition,"label":"label"},model:{value:(_vm.documents.existingDocument),callback:function ($$v) {_vm.$set(_vm.documents, "existingDocument", $$v)},expression:"documents.existingDocument"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3705911336)})],1):_vm._e(),_c('b-col',{staticClass:"mt-4 mb-2",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.submitting || !_vm.templateName}},[_vm._v(" Submit "),(_vm.submitting)?_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}):_vm._e()],1),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary","to":{ name: 'templates' }}},[_vm._v(" Cancel ")])],1)],1)],1)],1):_vm._e()],1),_c('b-modal',{attrs:{"centered":"","title":"Warning to lose current defination"},on:{"ok":_vm.changeUseExistingDefinition},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return ok()}}},[_vm._v(" Confirm ")])]}}]),model:{value:(_vm.showWarningModal),callback:function ($$v) {_vm.showWarningModal=$$v},expression:"showWarningModal"}},[_c('b-card-text',[_c('div',[_vm._v(" Are you sure you want to upadate or remove existing defination? ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }